import React, { useState } from 'react';
import '../styles/main.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

function Footer() {
	const [subscribed, setSubscribed] = useState(false);

	const handleNewsletterSubmit = (e) => {
		e.preventDefault();
		const email = e.target.email.value;
		fetch('https://api.crystalauraspa.com/subscribe', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email }),
		})
			.then((response) => response.text())
			.then((data) => {
				console.log(data);
				setSubscribed(true);
				e.target.reset();
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

	return (
		<footer className="footer">
			<div className="contact-info">
				<h3>Contact Us</h3>
				<p>Address: 22/2 Nimmana Haeminda Rd Lane 13, Tambon Su Thep, Amphoe Mueang Chiang Mai, Chang Wat Chiang Mai 50200</p>
				<p>Phone: 0959932861</p>
				<p>Email: crystalauramassage@gmail.com</p>
				<p>Opening Hours: 09:00 to 2400 hrs. 7 days a week.</p>
			</div>
			<div className="social-media">
				<h3>Follow Us</h3>
				<a
					href="https://www.instagram.com/crystalaura.cnx/?igsh=YTJ5bTlzaG5tOXVl&utm_source=qr"
					target="_blank"
					rel="noopener noreferrer"
				>
					<FontAwesomeIcon icon={faInstagram} size="2x" />
				</a>
				<a
					href="https://www.facebook.com/profile.php?id=61563111703990&mibextid=LQQJ4d"
					target="_blank"
					rel="noopener noreferrer"
				>
					<FontAwesomeIcon icon={faFacebook} size="2x" />
				</a>
			</div>
			<div className="newsletter">
				<h3>Subscribe to Our Newsletter</h3>
				<form onSubmit={handleNewsletterSubmit}>
					<input type="email" name="email" placeholder="Enter your email" required />
					<button type="submit" className="btn">Subscribe</button>
				</form>
				{subscribed && <p className="success-message">Subscribed successfully!</p>}
			</div>
			<div className="copyright">
				<p>&copy; 2024 Crystal Aura Spa. All rights reserved.</p>
			</div>
		</footer>
	);
}

export default Footer;
