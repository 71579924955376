import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo_shadow.webp';
import '../styles/main.scss';

function Header() {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	return (
		<header className="header">
			<div className="logo">
				<Link to="/">
					<img src={logo} alt="Crystal Aura Spa Logo" className="rounded-logo" />
				</Link>
			</div>
			<nav className={`navigation ${isOpen ? 'open' : ''}`}>
				<button className="menu-toggle" onClick={toggleMenu}>
					<span className="menu-icon"></span>
				</button>
				<ul>
					<li>
						<Link to="/" className="nav-link" data-scroll="home" onClick={toggleMenu}>
							Home
						</Link>
					</li>
					<li>
						<Link to="/services" className="nav-link" data-scroll="services" onClick={toggleMenu}>
							Services
						</Link>
					</li>
					<li>
						<Link to="/gallery" className="nav-link" data-scroll="gallery" onClick={toggleMenu}>
							Gallery
						</Link>
					</li>
					<li>
						<Link to="/contact" className="btn book-now" onClick={toggleMenu}>
							Book Now
						</Link>
					</li>
				</ul>
			</nav>
		</header>
	);
}

export default Header;
