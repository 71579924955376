import React from 'react';
import '../styles/main.scss';

function About() {
	return (
		<div className="about" style={{ backgroundColor: '#f6d9d5' }}>
			<section className="intro">
				<h1>About Crystal Aura Spa</h1>
				<p>
					Crystal Aura Spa was founded with a passion for bringing the benefits of crystal therapy
					to Chiang Mai. With a deep understanding of the healing properties of crystals,
					especially rose quartz, we have created a unique spa experience that
					combines traditional treatments with the power of crystals.
				</p>
				<p>
					At Crystal Aura Spa, we believe in the transformative power of
					self-care and the energy of crystals. Our mission is to provide a
					serene and nurturing environment where you can relax, rejuvenate, and
					reconnect with your inner self.
				</p>
			</section>
			<section className="philosophy">
				<h2>Our Philosophy</h2>
				<p>
					At Crystal Aura Spa, we believe that true wellness comes from a
					balance of the mind, body, and spirit. Our treatments are designed to
					address all aspects of your well-being, using the gentle and nurturing
					energy of crystals to promote healing and transformation.
				</p>
				<p>
					We are committed to providing a safe and welcoming space where you can
					unwind, recharge, and reconnect with yourself. Our experienced
					therapists are dedicated to tailoring each treatment to your unique
					needs, ensuring that you receive the personalized care and attention
					you deserve.
				</p>
			</section>
			<section className="our-services">
				<h2>Our Services</h2>
				<p>
					From our signature Crystal-Infused Oil Massages to our rejuvenating
					Rose Quartz Gua Sha Facials, we offer a wide range of treatments
					that harness the power of crystals and traditional Thai healing practices.
					Our packages and combo sets are designed to provide comprehensive
					wellness experiences, catering to all your relaxation and rejuvenation needs.
				</p>
			</section>
		</div>
	);
}

export default About;
