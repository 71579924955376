import React from 'react';
import '../styles/main.scss';

function Gallery() {
	return (
		<div className="gallery" style={{ backgroundColor: '#f6d9d5' }}>
			<h1>Gallery</h1>
			<div className="gallery-grid">
				<img src={require('../assets/bliss.png')} alt="A person receiving a massage" />
				<img src={require('../assets/facial.png')} alt="A person receiving a facial treatment" />
				<img src={require('../assets/foot.png')} alt="A person receiving a foot massage" />
				<img src={require('../assets/rose.png')} alt="Rose quartz crystal" />
				{/* Add more gallery images */}
			</div>
		</div>
	);
}

export default Gallery;
