import React, { useState } from 'react';
import '../styles/main.scss';

function Booking() {
	const [bookingConfirmed, setBookingConfirmed] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		const service = e.target.service.value;
		const date = e.target.date.value;
		const time = e.target.time.value;
		fetch('https://api.crystalauraspa.com/booking', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ service, date, time }),
		})
			.then((response) => response.text())
			.then((data) => {
				console.log(data);
				setBookingConfirmed(true);
				e.target.reset();
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

	return (
		<div className="booking" style={{ backgroundColor: '#f6d9d5' }}>
			<h1>Book Now</h1>
			<form className="booking-form" onSubmit={handleSubmit}>
				<div className="form-group">
					<label htmlFor="service">Select Service:</label>
					<select id="service" name="service">
						<option value="">Choose a service</option>
						{/* Thai Massage */}
						<optgroup label="Thai Massage">
							<option value="traditional-thai-massage">Traditional Thai Massage</option>
							<option value="4-hands-thai-massage">4 Hands Thai Massage</option>
							<option value="thai-yoga-massage">Thai Yoga Massage</option>
							<option value="thai-massage-herbal-ball-press">Thai Massage + Herbal Ball Press</option>
							<option value="thai-balm-massage">Thai Balm Massage</option>
							<option value="neck-back-shoulder-massage">Neck, Back, and Shoulder Massage</option>
						</optgroup>

						{/* Foot Massage */}
						<optgroup label="Foot Massage">
							<option value="foot-massage">Foot Massage</option>
							<option value="foot-reflexology">Foot Reflexology</option>
							<option value="foot-neck-back-shoulder-massage">Foot, Neck, Back, and Shoulder Massage</option>
							<option value="foot-scrub-reflexology">Foot Scrub and Foot Reflexology</option>
						</optgroup>
						{/* Head Massage */}
						<optgroup label="Head Massage">
							<option value="migraine-reliever-head-massage">Migraine Reliever Head Massage</option>
							<option value="indian-head-massage">Indian Head Massage</option>
						</optgroup>

						{/* Oil Massage */}
						<optgroup label="Oil Massage">
							<option value="aromatherapy-oil-massage">Aromatherapy Oil Massage</option>
							<option value="deep-tissue-massage">Deep Tissue Massage</option>
							<option value="cannabis-oil-massage">Cannabis Oil Massage</option>
							<option value="hot-stone-massage">Hot Stone Massage</option>
							<option value="swedish-oil-massage">Swedish Oil Massage</option>
							<option value="aloe-vera-gel-treatment">Aloe Vera Gel Treatment</option>
							<option value="coconut-oil-massage">Coconut Oil Massage</option>
							<option value="hot-oil-massage">Hot Oil Massage</option>
						</optgroup>

						{/* Facials */}
						<optgroup label="Facials">
							<option value="classic-facial">Classic Facial</option>
							<option value="facial-face-massage">Facial and Face Massage</option>
						</optgroup>

						{/* Body Scrub */}
						<optgroup label="Body Scrub">
							<option value="himalayan-salt-scrub">Himalayan Salt Scrub</option>
							<option value="coffee-scrub">Coffee Scrub</option>
						</optgroup>

						{/* Speciality Massages */}
						<optgroup label="Speciality Massages">
							<option value="rose-quartz-gua-sha-facial">Rose Quartz Gua Sha Facial</option>
							<option value="crystal-infused-oil-massage">Crystal-Infused Oil Massage</option>
							<option value="rose-quartz-stone-therapy">Rose Quartz Stone Therapy with Nourishing Oil</option>
							<option value="rose-quartz-foot-massage">Rose Quartz Foot Massage</option>
							<option value="guided-meditation-massage">Guided Meditation Massage</option>
						</optgroup>

						{/* Packages */}
						<optgroup label="Packages">
							<option value="thai-bliss-package">Thai Bliss Package (2.5 hours, 1450 Bath)</option>
							<option value="ultimate-rejuvenation-package">Ultimate Rejuvenation Package (2.5 hours, 1650 Bath)</option>
							<option value="relaxation-oasis-package">Relaxation Oasis Package (2.5 hours, 2250 Bath)</option>
							<option value="revitalizing-glow-package">Revitalizing Glow Package (2.5 hours, 2450 Bath)</option>
							<option value="soothing-retreat-package">Soothing Retreat Package (2.5 hours, 1650 Bath)</option>
							<option value="foot-bliss-package">Foot Bliss Package (2.5 hours, 2050 Bath)</option>
							<option value="head-foot-relief-package">Head and Foot Relief Package (2.5 hours, 1350 Bath)</option>
							<option value="rejuvenation-skin-package">Rejuvenation Skin (3 hours, 2550 Bath)</option>
							<option value="crystal-harmony-package">Crystal Harmony Package (3 hours, 2650 Bath)</option>
							<option value="tranquil-meditation-spa-package">Tranquil Meditation Spa Package (3 hours, 2150 Bath)</option>
							<option value="harmonious-spa-escape">Harmonious Spa Escape (3 hours, 2250 Bath)</option>
							<option value="serene-wellness-retreat">Serene Wellness Retreat (3 hours, 3000 Bath)</option>
							<option value="tranquil-spa-sanctuary">Tranquil Spa Sanctuary (4 hours, 3950 Bath)</option>
						</optgroup>

						{/* New Combo Sets */}
						<optgroup label="Combo Sets">
							<option value="combo-set-90">Combo Set (90 mins, 650 Bath)</option>
							<option value="combo-set-90-3">Combo Set (3 chosen, 90 mins, 990 Bath)</option>
							<option value="combo-set-120-3">Combo Set (3 chosen, 120 mins, 1,200 Bath)</option>
						</optgroup>
					</select>
				</div>
				<div className="form-group">
					<label htmlFor="date">Select Date:</label>
					<input type="date" id="date" name="date" />
				</div>
				<div className="form-group">
					<label htmlFor="time">Select Time:</label>
					<input type="time" id="time" name="time" />
				</div>
				<button type="submit" className="btn">Book Appointment</button>
			</form>
			{bookingConfirmed && <p className="success-message">Booking confirmed!</p>}
			<p>Our services are available from 09:00 hrs to 2400 hrs, 7 days a week.</p>
		</div>
	);
}
export default Booking;
