import React from 'react';
import './FeaturedServicesCard.scss';

function FeaturedServicesCard({ image, title, description }) {
	return (
		<div className="featured-service-card">
			<div className="featured-service-image">
				<img src={image} alt={title} />
			</div>
			<div className="featured-service-content">
				<h3>{title}</h3>
				<p>{description}</p>
			</div>
		</div>
	);
}

export default FeaturedServicesCard;
