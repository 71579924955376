import React from 'react';
import '../styles/main.scss';

function Services() {
	return (
		<div className="services" style={{ backgroundColor: '#f6d9d5' }}>
			<h1>Our Services</h1>
			{/* Thai Massage */}
			<section className="service" style={{ backgroundColor: '#f6d9d5' }}>
				<img src={require('../assets/services1.jpeg')} alt="Thai Massage" />
				<div className="service-details">
					<h2>Thai Massage</h2>
					<div className="service-item">
						<img src={require('../assets/traditional_thai_massage.webp')} alt="Traditional Thai Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Traditional Thai Massage</h3>
							<p>Experience the ancient art of Thai massage, a holistic therapy that combines acupressure, stretching, and rhythmic compression techniques to promote relaxation, flexibility, and energy flow throughout the body.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/4_hands_thai_massage.webp')} alt="4 Hands Thai Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>4 Hands Thai Massage</h3>
							<p>Double the hands, double the relaxation. Our synchronized Thai massage with two therapists blends traditional Thai techniques to melt away tension and restore balance. Experience unparalleled rejuvenation with stretches, acupressure, and rhythmic movements that promote healing and well-being.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/thai_yoga_massage.webp')} alt="Thai Yoga Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Thai Yoga Massage</h3>
							<p>Embark on a journey of flexibility and relaxation with our Thai yoga massage, combining gentle stretching and rhythmic pressure to harmonize your body and mind.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/thai_massage_and_herbal_ball_press.webp')} alt="Thai Massage + Herbal Ball Press" className="service-thumbnail" />
						<div className="service-description">
							<h3>Thai Massage + Herbal Ball Press</h3>
							<p>Elevate your Thai massage experience with the therapeutic benefits of herbal ball press therapy, promoting detoxification, muscle relaxation, and overall well-being.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/thai_balm_massage.webp')} alt="Thai Balm Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Thai Balm Massage</h3>
							<p>Soothe your senses and invigorate your body with our Thai balm massage, harnessing the power of traditional herbal balms to relieve tension and promote circulation.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/neck_back_and_shoulder_massage.webp')} alt="Neck, Back, and Shoulder Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Neck, Back, and Shoulder Massage</h3>
							<p>Relieve tension and soothe tired muscles with a focused massage targeting the neck, back, and shoulders. This therapeutic treatment incorporates a blend of massage techniques to alleviate stiffness and promote relaxation.</p>
						</div>
					</div>
				</div>
			</section>

			{/* Foot Treatments */}
			<section className="service" style={{ backgroundColor: '#f6d9d5' }}>
				<img src={require('../assets/servicesfeet.jpeg')} alt="Foot Massage" />
				<div className="service-details">
					<h2>Foot Treatments</h2>
					<div className="service-item">
						<img src={require('../assets/rose_quartz_foot_massage.webp')} alt="Crystal Foot Reflexology" className="service-thumbnail" />
						<div className="service-description">
							<h3>Crystal Foot Reflexology</h3>
							<p>Indulge in a calming foot massage with smooth rose quartz stones, promoting relaxation, improved circulation, and a sense of well-being, in tune with the healing energy of rose quartz.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/foot_massage.webp')} alt="Foot Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Foot Massage</h3>
							<p>Treat your feet to a well-deserved break with our Regular Foot Massage. Relax as skilled therapists apply soothing massage techniques to ease tension and revitalize tired feet. This treatment improves circulation, relieves stress, and leaves you feeling refreshed and rejuvenated.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/foot_reflexology.webp')} alt="Foot Reflexology" className="service-thumbnail" />
						<div className="service-description">
							<h3>Foot Reflexology</h3>
							<p>Experience the ancient art of foot reflexology, where precise pressure is applied to specific points on your feet to stimulate healing and relaxation throughout your body.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/foot_neck_back_and_shoulder_massage.webp')} alt="Foot, Neck, Back, and Shoulder Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Foot, Neck, Back, and Shoulder Massage</h3>
							<p>Indulge in pure bliss with this comprehensive massage, focusing on your feet, neck, back, and shoulders. Let tension melt away as skilled therapists work their magic, leaving you feeling rejuvenated from head to toe.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/foot_scrub_and_foot_reflexology.webp')} alt="Foot Scrub and Foot Reflexology" className="service-thumbnail" />
						<div className="service-description">
							<h3>Foot Scrub and Foot Reflexology</h3>
							<p>Treat your feet to the ultimate pampering with a revitalizing foot scrub followed by a soothing session of foot reflexology. Say goodbye to dry, tired skin and hello to renewed vitality and relaxation.</p>
						</div>
					</div>
				</div>
			</section>

			{/* Head Massage */}
			<section className="service" style={{ backgroundColor: '#f6d9d5' }}>
				<img src={require('../assets/services2.jpeg')} alt="Head Massage" />
				<div className="service-details">
					<h2>Head Massage</h2>
					<div className="service-item">
						<img src={require('../assets/migraine_reliever_head_massage.webp')} alt="Migraine Reliever Head Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Migraine Reliever Head Massage</h3>
							<p>Find relief from headaches and migraines with our specialized head massage, focusing on pressure points and gentle strokes to ease tension and promote relaxation.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/indian_head_massage.webp')} alt="Indian Head Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Indian Head Massage</h3>
							<p>Discover serenity with our Indian Head Massage. Let skilled hands ease tension in your scalp and neck, promoting relaxation and clarity.</p>
						</div>
					</div>
				</div>
			</section>

			{/* Oil Massage */}
			<section className="service" style={{ backgroundColor: '#f6d9d5' }}>
				<img src={require('../assets/services5.jpeg')} alt="Oil Massage" />
				<div className="service-details">
					<h2>Oil Massage</h2>
					<div className="service-item">
						<img src={require('../assets/crystal_infused_oil_massage.webp')} alt="Crystal-Infused Oil Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Crystal-Infused Oil Massage</h3>
							<p>Immerse yourself in the harmonizing energy of rose quartz-infused oils, promoting self-love and inner harmony while nurturing your skin.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/aromatherapy_oil_massage.webp')} alt="Aromatherapy Oil Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Aromatherapy Oil Massage</h3>
							<p>Immerse yourself in pure relaxation with our aromatherapy oil massage, where fragrant essential oils are expertly blended and applied to the body using long, flowing strokes. This soothing treatment calms the mind, relaxes the body, and enhances overall vitality.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/deep_tissue_massage.webp')} alt="Deep Tissue Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Deep Tissue Massage</h3>
							<p>Release deep-seated tension and muscle knots with our deep tissue massage, applying firm pressure to target specific areas of tension and promote overall well-being.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/cannabis_oil_massage.webp')} alt="Cannabis Oil Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Cannabis Oil Massage</h3>
							<p>Revitalize your senses with our Cannabis Oil Massage. Experience deep relaxation and relief as the soothing properties of cannabis-infused oil melt away tension and stress, leaving you feeling rejuvenated and tranquil.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/hot_stone_massage.webp')} alt="Hot Stone Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Hot Stone Massage</h3>
							<p>Experience the soothing warmth of smooth, heated stones as they melt away tension and stress, leaving you feeling deeply relaxed and revitalized.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/swedish_oil_massage.webp')} alt="Swedish Oil Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Swedish Oil Massage</h3>
							<p>Drift into a state of tranquility with our Swedish oil massage, combining long, flowing strokes with gentle kneading to promote relaxation and improve circulation.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/aloe_vera_gel_treatment.webp')} alt="Aloe Vera Gel Treatment" className="service-thumbnail" />
						<div className="service-description">
							<h3>Aloe Vera Gel Treatment</h3>
							<p>Nourish and hydrate your skin with our refreshing aloe vera gel treatment, leaving your skin feeling soft, supple, and rejuvenated.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/coconut_oil_massage.webp')} alt="Coconut Oil Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Coconut Oil Massage</h3>
							<p>Transport yourself to a tropical paradise with our coconut oil massage, featuring the nourishing benefits of coconut oil to hydrate your skin and soothe your senses.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/hot_oil_massage.webp')} alt="Hot Oil Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Hot Oil Massage</h3>
							<p>Melt away tension and stress with our indulgent hot oil massage, featuring warm oils that penetrate deep into your muscles for maximum relaxation and rejuvenation.</p>
						</div>
					</div>
				</div>
			</section>

			{/* Facials */}
			<section className="service" style={{ backgroundColor: '#f6d9d5' }}>
				<img src={require('../assets/services1.jpeg')} alt="Facials" />
				<div className="service-details">
					<h2>Facials</h2>
					<div className="service-item">
						<img src={require('../assets/rose_quartz_gua_sha_facial.webp')} alt="Rose Quartz Gua Sha Facial" className="service-thumbnail" />
						<div className="service-description">
							<h3>Rose Quartz Gua Sha Facial</h3>
							<p>Renew and rejuvenate with a facial treatment incorporating a rose quartz tool, sculpting your features and enhancing a youthful glow, fostering self-care and balance.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/classic_facial.webp')} alt="Classic Facial" className="service-thumbnail" />
						<div className="service-description">
							<h3>Classic Facial</h3>
							<p>Restore your skin's natural radiance with our classic facial, tailored to your skin type to cleanse, exfoliate, and nourish for a healthy, glowing complexion.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/facial_and_face_massage.webp')} alt="Facial and Face Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Facial and Face Massage</h3>
							<p>Treat yourself to the ultimate pampering experience with our facial and face massage combo, combining the benefits of a classic facial with a soothing massage to relax facial muscles and enhance circulation.</p>
						</div>
					</div>
				</div>
			</section>

			{/* Body Scrub */}
			<section className="service" style={{ backgroundColor: '#f6d9d5' }}>
				<img src={require('../assets/services3.jpeg')} alt="Body Scrub" />
				<div className="service-details">
					<h2>Body Scrub</h2>
					<div className="service-item">
						<img src={require('../assets/himalayan_salt_scrub.webp')} alt="Himalayan Salt Scrub" className="service-thumbnail" />
						<div className="service-description">
							<h3>Himalayan Salt Scrub</h3>
							<p>Exfoliate and detoxify your skin with our mineral-rich Himalayan Salt Scrub, leaving you with a smooth and radiant glow. This revitalizing treatment improves circulation and nourishes your skin with essential minerals.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/coffee_scrub.webp')} alt="Coffee Scrub" className="service-thumbnail" />
						<div className="service-description">
							<h3>Coffee Scrub</h3>
							<p>Invigorate your skin with our Coffee Scrub, designed to exfoliate and tighten, leaving your skin smooth and rejuvenated. This energizing treatment stimulates blood flow and helps reduce the appearance of cellulite.</p>
						</div>
					</div>
				</div>
			</section>

			{/* Speciality Massages */}
			<section className="service" style={{ backgroundColor: '#f6d9d5' }}>
				<img src={require('../assets/services2.jpeg')} alt="Speciality Massages" />
				<div className="service-details">
					<h2>Speciality Massages</h2>
					<div className="service-item">
						<img src={require('../assets/rose_quartz_gua_sha_facial.webp')} alt="Rose Quartz Gua Sha Facial" className="service-thumbnail" />
						<div className="service-description">
							<h3>Rose Quartz Gua Sha Facial</h3>
							<p>Renew and rejuvenate with a facial treatment incorporating a rose quartz tool, sculpting your features and enhancing a youthful glow, fostering self-care and balance.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/crystal_infused_oil_massage.webp')} alt="Crystal-Infused Oil Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Crystal-Infused Oil Massage</h3>
							<p>Immerse yourself in the harmonizing energy of rose quartz-infused oils, promoting self-love and inner harmony while nurturing your skin.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/rose_quartz_stone_therapy_with_nourishing_oil.webp')} alt="Rose Quartz Stone Therapy with Nourishing Oil" className="service-thumbnail" />
						<div className="service-description">
							<h3>Rose Quartz Stone Therapy with Nourishing Oil</h3>
							<p>Balance your energy and relax deeply with our Rose Quartz Stone Therapy with Nourishing Oil. This treatment combines the strategic placement of rose quartz crystals on your body with the application of crystal-infused oils to align your chakras, reduce stress, and promote a sense of inner peace and harmony.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/rose_quartz_foot_massage.webp')} alt="Crystal Foot Reflexology" className="service-thumbnail" />
						<div className="service-description">
							<h3>Crystal Foot Reflexology</h3>
							<p>Indulge in a calming foot massage with smooth rose quartz stones, promoting relaxation, improved circulation, and a sense of well-being, in tune with the healing energy of rose quartz.</p>
						</div>
					</div>
					<div className="service-item">
						<img src={require('../assets/guided_meditation_massage.webp')} alt="Guided Meditation Massage" className="service-thumbnail" />
						<div className="service-description">
							<h3>Guided Meditation Massage</h3>
							<p>Experience serenity with our Guided Meditation Massage. Combining gentle touch with mindful guidance, this treatment fosters relaxation, presence, and inner calm, leaving you refreshed and centered.</p>
						</div>
					</div>
				</div>
			</section>

			{/* Packages */}
			<section className="service" style={{ backgroundColor: '#f6d9d5' }}>
				<img src={require('../assets/services6.jpeg')} alt="Packages" />
				<div className="service-details">
					<h2>Packages</h2>
					<ul>
						<li>Thai Bliss Package (2.5 hours, 1450 Bath): Immerse yourself in relaxation with a Traditional Thai Massage, Foot Scrub, and Foot Reflexology.</li>
						<br />
						<li>Ultimate Rejuvenation Package (2.5 hours, 1650 Bath): Treat yourself to a Rose Quartz Foot Massage, Crystal-Infused Oil Massage, and Classic Facial for the ultimate pampering experience.</li>
						<br />
						<li>Relaxation Oasis Package (2.5 hours, 2250 Bath): Unwind and release tension with a Deep Tissue Massage and invigorating Coffee Scrub.</li>
						<br />
						<li>Revitalizing Glow Package (2.5 hours, 2450 Bath): Refresh your mind, body, and skin with an Aromatherapy Oil Massage and Classic Facial.</li>
						<br />
						<li>Soothing Retreat Package (2.5 hours, 1650 Bath): Relax and recharge with a soothing Oil Massage and Foot Reflexology.</li>
						<br />
						<li>Foot Bliss Package (2.5 hours, 2050 Bath): Pamper your feet with a Regular Foot Massage and nourishing Oil Massage.</li>
						<br />
						<li>Head and Foot Relief Package (2.5 hours, 1350 Bath): Find relief from headaches and tired feet with a Migraine Reliever Head Massage and Foot Massage.</li>
						<br />
						<li>Rejuvenation Skin (3 hours, 2550 Bath): Enjoy a Himalayan Salt Scrub, a soothing Hot Aroma Massage to ease tension, and a revitalizing face massage, leaving your skin glowing, refreshed, and rejuvenated.</li>
						<br />
						<li>Crystal Harmony Package (3 hours, 2650 Bath): Experience the healing power of crystals with a Crystal-Infused Oil Massage, Rose Quartz Foot Massage, and Rose Quartz Gua Sha Facial.</li>
						<br />
						<li>Tranquil Meditation Spa Package (3 hours, 2150 Bath): Experience inner peace and relaxation with a Guided Meditation Massage, Himalayan Salt Scrub, and Foot Massage.</li>
						<br />
						<li>Harmonious Spa Escape (3 hours, 2250 Bath): Indulge in a Foot Reflexology, Swedish Oil Massage, Classic Facial, and Coffee Scrub for total-body harmony.</li>
						<br />
						<li>Serene Wellness Retreat (3 hours, 3000 Bath): Unwind and rejuvenate with a Rose Quartz Foot Massage, Aromatherapy Oil Massage, Deep Tissue Massage, and Classic Facial.</li>
						<br />
						<li>Tranquil Spa Sanctuary (4 hours, 3950 Bath): Relax and rebalance with a Thai Massage + Herbal Ball Press, Himalayan Salt Scrub, and Facial and Face Massage.</li>
					</ul>
				</div>
			</section>

			{/* New Combo Sets Section */}
			<section className="service" style={{ backgroundColor: '#f6d9d5' }}>
				<h2>Combo Sets</h2>
				<div className="service-details">
					<div className="service-item">
						<h3>Combo Set (90 mins, 650 Bath)</h3>
						<ul>
							<li>Foot + Head Back and Shoulder massage</li>
							<li>Head Back and Shoulder + Traditional Thai massage</li>
							<li>Traditional Thai massage + Foot massage</li>
						</ul>
					</div>
					<div className="service-item">
						<h3>Combo Set (3 chosen, 90 mins, 990 Bath)</h3>
						<p>Choose 3 from:</p>
						<ul>
							<li>Traditional Thai massage</li>
							<li>Head Back and Shoulder massage</li>
							<li>Foot massage</li>
							<li>Aromatherapy oil massage</li>
							<li>Hot Aromatherapy oil massage</li>
						</ul>
					</div>
					<div className="service-item">
						<h3>Combo Set (3 chosen, 120 mins, 1,200 Bath)</h3>
						<p>Choose 3 from:</p>
						<ul>
							<li>Traditional Thai massage</li>
							<li>Head Back and Shoulder massage</li>
							<li>Foot massage</li>
							<li>Aromatherapy oil massage</li>
							<li>Hot Aromatherapy oil massage</li>
						</ul>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Services;
