import React from 'react';
import '../styles/main.scss';

function Products() {
	return (
		<div className="products">
			<h1>Products & Crystals</h1>
			<section className="product" style={{ backgroundColor: '#f6d9d5' }}>
				<img src={require('../assets/rose.png')} alt="Rose Quartz" />
				<div className="product-details">
					<h2>Rose Quartz</h2>
					<p>
						The stone of unconditional love, rose quartz promotes emotional
						healing, self-love, and inner peace.
					</p>
					<p>Price: $30</p>
				</div>
			</section>
			{/* Add more product sections */}
		</div>
	);
}

export default Products;
