import React from 'react';
import FeaturedServiceCard from '../components/FeaturedServicesCard';
import '../styles/main.scss';

function Home() {
	return (
		<div className="home">
			<section className="hero">
				<h1>Welcome to Crystal Aura Massage and Spa</h1>
				<p>
					Discover the healing power of crystals at Chiang Mai's first
					crystal-infused spa.
				</p>
				<div className="hero-description">
					<p>
						Crystal Aura Massage & Spa is a premier wellness destination located in the vibrant Nimman area of Chiang Mai, Thailand. We provide professional massage and therapeutic services, employing certified therapists and adhering to health standards set by the Ministry of Health. Our commitment to customer satisfaction and high-quality service has earned us the best rating on Google.
					</p>
				</div>
			</section>
			<section className="featured-services">
				<h2>Featured Services</h2>
				<div className="service-grid">
					<FeaturedServiceCard
						image={require('../assets/rose-quartz-foot-massage.png')}
						title="Rose Quartz Foot Massage"
						description="Experience deep relaxation with our signature rose quartz foot massage."
					/>
					<FeaturedServiceCard
						image={require('../assets/crystal_infused_oil_massage.webp')}
						title="Crystal-Infused Oil Massage"
						description="Immerse yourself in the harmonizing energy of rose quartz-infused oils for inner harmony and nourished skin."
					/>
					<FeaturedServiceCard
						image={require('../assets/traditional_thai_massage.webp')}
						title="Traditional Thai Massage"
						description="Immerse yourself in the ancient art of Thai massage for ultimate relaxation and flexibility."
					/>
				</div>
			</section>
			<section className="testimonials">
				<h2>What Our Clients Say</h2>
				<div className="testimonial-grid">
					<blockquote>
						"The Crystal Harmony Package was absolutely divine. I left
						feeling rejuvenated and at peace."
					</blockquote>
					<blockquote>
						"Crystal Aura Spa is my go-to place for relaxation and self-care.
						The staff is amazing, and the treatments are top-notch."
					</blockquote>
					<blockquote>
						"I popped in here to escape the heat and was impressed by the cleanliness of the facilities. The bathroom was very clean and modern. The place is well kept and smells nice. The Thai massage was very good and just what I needed. Nice music and attentive, friendly staff."
					</blockquote>
					<blockquote>
						"Thai traditional massage and hot compress is very satisfying. The price is reasonable for a service. Highly recommend the best massage in Nimman."
					</blockquote>
					<blockquote>
						"New shop, clean and tidy. At the centre of Nimman. Worth the money."
					</blockquote>
					<blockquote>
						"Awesome. The lady was a specialist, and I liked her nice smile. I strongly recommend to get their massage when you are exhausted from traveling. I wish they would come to my country… I miss them."
					</blockquote>
				</div>
			</section>
		</div>
	);
}

export default Home;
